import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import "../ComponentsCSS/navabr.css";
import { useNavigate } from 'react-router-dom';
const Navbar = () => {
    const navigate = useNavigate();
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        } else {
            window.location.href('https://youthfestivalplus.com/');
        }
    };


    return (
        <div className='youthfestival'>
            <nav class="navbar navbar-expand-lg" style={{ background: '#000000',color:'#ffffff' }}>
                <div class="container-fluid">
                    <Link to="https://youthfestivalplus.com/" className='navbar-brand'><img className='logo' src="./festivalimages/LogoImg.png" /></Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="mx-auto navbar-nav me-auto mb-2 mb-lg-0">
                            {/* <li class="nav-item">
                                <Link to="/youthfestivalplus" className='navlink'>HOME</Link>
                            </li> */}
                            <li class="nav-item">
                                <Link onClick={() => scrollToSection('section1')} className='navlink'> Highlights</Link>
                            </li>
                            <li class="nav-item">
                                <Link onClick={() => scrollToSection('section2')} className='navlink'> Stage Zone</Link>
                            </li>
                            <li class="nav-item">
                                <Link onClick={() => scrollToSection('section3')} className='navlink'>Experience Centre</Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/youthfestivalplus/Login" className='navlink'>LOGIN</Link>
                            </li>
                        </ul>
                    </div>
                    {/* <Link to="/youthfestivalplus/applicationform" className='text-white px-4'>
                        APPLY NOW
                    </Link> */}
                </div>
            </nav>

            {/* <header className='w-full py-5 sm:px-10 px-5 flex justify-between items-center'>
                <nav className='flex w-full screen-max-width'>
                    <NavLink to="/">
                        <img src="./images/LogoImg.png" width={100} height={20} />
                    </NavLink>
                    <div className='flex flex-1 justify-center max-sm:hidden'>
                        <a href="#highlights" className='px-5 py-11 text-sm cursor-pointer text-gray hover:text-white transition-all'>
                            Highlights
                        </a>
                        <a href="#highlights2" className='px-5 py-11 text-sm cursor-pointer text-gray hover:text-white transition-all'>
                            Stage Zone
                        </a>
                        <a href="#center" className='px-5 py-11 text-sm cursor-pointer text-gray hover:text-white transition-all'>
                            Experience Centre
                        </a>
                    </div>
                    <div className='flex items-baseline gap-7 max-sm:justify-end max-sm:flex-1'>
                        <NavLink to="https://firstindiaplus.com/#/youthfestivalplus/applicationform">
                            <button className='py-10'>Apply Now</button>
                        </NavLink>
                    </div>
                </nav>
            </header> */}
        </div>
    )
}
export default Navbar;






