import React from 'react';

const Footer = () => {
  return (
    <footer className="py-3 sm:px-10 px-5 bg-neutral-900 text-white" style={{backgroundColor:'#171717'}}>
      <div className="screen-max-width">
        {/* Contact Info */}
        <div>
          <p className="font-semibold text-gray-400 text-xs mb-1" style={{color: '#9ca3af',fontSize:'12px',fontWeight:'600'}}>
            Find us at:{' '}
            <a
              href="https://firstindiaplus.com/"
              className="underline text-blue"
              rel="noopener noreferrer"
              target="_blank"
            >
              firstindiaplus.com
            </a>
          </p>
          <p className="font-semibold text-blue text-xs" style={{color: '#2997ff',fontSize:'12px',fontWeight:'600'}}>Or call +91 7300064357</p>
        </div>

        {/* Divider */}
        <div className="bg-neutral-700 my-3 h-[1px] w-100" style={{height:'1px',backgroundColor:'#404040'}}/>

        {/* Footer Bottom Section */}
        <div className="d-md-flex flex-col md:flex-row md:items-center justify-content-between py-1">
          <p className="font-semibold text-gray-400 text-xs mb-2" style={{color: '#9ca3af',fontSize:'12px',fontWeight:'600',fontFamily:'sans-serif'}}>
            Copyright © 2024 First India+ All rights reserved.
          </p>
          <div className="d-flex flex-wrap space-x-4 text-white" style={{gap:'10px'}} >
            <a className='' href="https://www.instagram.com/firstindiaplus" target="_blank" rel="noopener noreferrer">
              <img className="w-6 h-6 object-contain filter invert" src="/festivalimages/Instagram.svg" style={{width:'1.5rem'}} alt="Instagram icon" />
            </a>
            <a href="https://bit.ly/fipyoutube" target="_blank" rel="noopener noreferrer">
              <img className="w-6 h-6 object-contain filter invert" src="/festivalimages/Youtube.svg" alt="YouTube icon" style={{width:'1.5rem'}}/>
            </a>
            <a href="https://www.facebook.com/firstindiaplus" target="_blank" rel="noopener noreferrer">
              <img className="w-6 h-6 object-contain filter invert" src="/festivalimages/FB.svg" alt="Facebook icon" style={{width:'1.5rem'}}/>
            </a>
            <a
              href="https://www.linkedin.com/company/firstindiaplus/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="w-6 h-6 object-contain filter invert" src="/festivalimages/Linkedin.svg" alt="LinkedIn icon" style={{width:'1.5rem'}}/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;