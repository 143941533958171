import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RedirectIfDownloads = () => {
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        if (pathname.toLowerCase().includes('downloads'.toLowerCase())) {
            console.log('The pathname contains "downloads" (case-insensitive)');
            // Redirect to the desired URL
            window.location.href = 'https://firstindiaplus.com/downloads/index.html';
        }
    }, [pathname]);  // Run whenever the pathname changes

    return null;  // This component doesn't render anything
};

export default RedirectIfDownloads;
